'use client'

import HomePacks from "components/packs/homepacks"
import { useEffect, useState } from "react"

const params = {
    filter: ['type=package', 'collections=29'],
    sort: [
        "price:asc",
        "_count:desc",
    ]
}

export default function BudgetCards() {
    const [isMonoton, setIsMonoton] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsMonoton((prev) => !prev);
        }, 400);
        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    return (
        <div className='mt-2'>
            <h3 className={`pb-3 text-center text-3xl font-black`} style={{height: 57}}>
                <span className={isMonoton ? "font-overcameoutline" : "font-overcamebold"}>Budget Events</span>
            </h3>
            <div className="">
                <HomePacks api={'/api/external/ai/search'} params={params} query={'type=packages'} apiType='' />
            </div>
        </div>
    )
}   